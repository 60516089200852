<template>
    <div class="gruppen ranking-list" v-if="isDataLoaded">
        <div v-if="showEmptyState">
            <md-empty-state
                md-icon="groups"
                :md-label="lang.turnierplan.emptyStateLabel"
                :md-description="lang.turnierplan.emptyStateDescription">
            </md-empty-state>
        </div>
        <div class="ranking-container" v-else>
            <p class="abbreviationDescription">
                <span>{{lang.listGruppen.abbreviationDescriptionSpiele}}</span>
                <span>{{lang.listGruppen.abbreviationDescriptionSiege}}</span>
                <span>{{lang.listGruppen.abbreviationDescriptionNiederlage}}</span>
            </p>
            <p class="abbreviationDescription">
                <span>{{lang.listGruppen.abbreviationDescriptiongetroffeneBecher}}</span>
                <span>{{lang.listGruppen.abbreviationDescriptionverloreneBecher}}</span>
                <span>{{lang.listGruppen.abbreviationDescriptionBecherdifferenz}}</span>
            </p>
            <div class="gruppe-table-team card" v-if="gruppeTeam != null">
                <span class="table-header font-color-secondary">{{lang.listGruppen.labelGruppe}} {{gruppeTeam.gruppenName}}</span>
                <md-table>
                    <md-table-row>
                        <md-table-head md-numeric></md-table-head>
                        <md-table-head></md-table-head>
                        <md-table-head md-numeric>{{lang.listGruppen.abbreviationSpiele}}</md-table-head>
                        <md-table-head md-numeric>{{lang.listGruppen.abbreviationSiege}}</md-table-head> 
                        <md-table-head md-numeric>{{lang.listGruppen.abbreviationNiederlage}}</md-table-head>
                        <md-table-head md-numeric>{{lang.listGruppen.abbreviationgetroffeneBecher}}</md-table-head>
                        <md-table-head md-numeric>{{lang.listGruppen.abbreviationverloreneBecher}}</md-table-head>
                        <md-table-head md-numeric>{{lang.listGruppen.abbreviationBecherdifferenz}}</md-table-head>
                    </md-table-row>
                    <md-table-row v-for="(team, index) in gruppeTeam.gruppenTeams" :key="index">
                        <md-table-cell md-numeric>{{team.platzierung}}</md-table-cell>
                        <md-table-cell :class="(zweiterPlatzWeiterIndex >= team.platzierung) && (team.anzahlGespielteSpiele != 0) ? 'nextRoundColor' : ''" class="teamName">{{team.team.teamName}}</md-table-cell>
                        <md-table-cell md-numeric>{{team.anzahlGespielteSpiele}}</md-table-cell>
                        <md-table-cell class="siege" md-numeric>{{team.siege}}</md-table-cell>
                        <md-table-cell md-numeric>{{team.anzahlGespielteSpiele - team.siege}}</md-table-cell>
                        <md-table-cell md-numeric>{{team.getroffeneBecher}}</md-table-cell>
                        <md-table-cell md-numeric>{{team.verloreneBecher}}</md-table-cell>
                        <md-table-cell md-numeric>{{team.punkteDifferenz}}</md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
            <md-divider></md-divider>
            <div class="gruppe-table">
                <div class="card" v-for="gruppe in gruppen" :key="gruppe.gruppenID">
                    <span class="table-header" 
                        :class="gruppeTeamID == gruppe.gruppenID ? 'font-color-secondary' : ''">{{lang.listGruppen.labelGruppe}} {{gruppe.gruppenName}}</span>
                    <md-table>
                        <md-table-row>
                            <md-table-head md-numeric></md-table-head>
                            <md-table-head></md-table-head>
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationSpiele}}</md-table-head>
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationSiege}}</md-table-head> 
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationNiederlage}}</md-table-head>
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationgetroffeneBecher}}</md-table-head>
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationverloreneBecher}}</md-table-head>
                            <md-table-head md-numeric>{{lang.listGruppen.abbreviationBecherdifferenz}}</md-table-head>
                        </md-table-row>
                        <md-table-row v-for="(team, index) in gruppe.gruppenTeams" :key="index">
                            <md-table-cell md-numeric>{{team.platzierung}}</md-table-cell>
                            <md-table-cell :class="(zweiterPlatzWeiterIndex >= team.platzierung) && (team.anzahlGespielteSpiele != 0) ? 'nextRoundColor' : ''" class="teamName">{{team.team.teamName}}</md-table-cell>
                            <md-table-cell md-numeric>{{team.anzahlGespielteSpiele}}</md-table-cell>
                            <md-table-cell class="siege" md-numeric>{{team.siege}}</md-table-cell>
                            <md-table-cell md-numeric>{{team.anzahlGespielteSpiele - team.siege}}</md-table-cell>
                            <md-table-cell md-numeric>{{team.getroffeneBecher}}</md-table-cell>
                            <md-table-cell md-numeric>{{team.verloreneBecher}}</md-table-cell>
                            <md-table-cell md-numeric>{{team.punkteDifferenz}}</md-table-cell>
                        </md-table-row>
                    </md-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store/index'

export default {
    name: 'ListGruppenByTurnier',
    props: ['turnierID'],
    created() {
        store.commit("SET_DATA_LOADED_STATUS_GRUPPEN", false);
        store.dispatch('getGruppenByTurnierID', this.turnierID);
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        },
        spielerID() {
            return store.getters.getSpielerID;
        },
        teamIDByPlayer() {
            return store.getters.getPlayerTeamNumber(this.spielerID);
        },
        gruppeTeam() {
            if (this.teamIDByPlayer == undefined || this.teamIDByPlayer == 0) {
                return null;
            } else {
                return store.getters.getGruppeByTeam(this.teamIDByPlayer)[0];
            }
        },
        gruppeTeamID() {
            if (this.gruppeTeam != null) {
                return this.gruppeTeam.gruppenID;
            } else {
                return -1;
            }
        },
        gruppen() {
            return store.getters.getGruppenDetails;
        },
        zweiterPlatzWeiterIndex() {
            return store.getters.getTurnier.gruppenPhaseZweiterWeiter ? 2 : 1;
        },
        isDataLoaded() {
            return store.state.gruppe.isDataLoaded;
        },
        showEmptyState() {
            return (this.gruppen.length === 0);
        }
    },
}
</script>

<style lang="scss" scoped>
</style>