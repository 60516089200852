<template>
    <div>
        <ListGruppenByTurnier :turnierID="turnierID"/>
    </div>
</template>

<script>
import ListGruppenByTurnier from '@/components/Turnier/ListGruppenByTurnier.vue'

export default {
    name: 'ListGruppen',
    props: ['turnierID'],
    components: {
        ListGruppenByTurnier
    }
}
</script>
